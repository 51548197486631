<template>
  <div class="studyFirstStep">
    <div class="leftnav">
      <Step :active="2"/>
    </div>
    <audio ref="audio" muted controls :src="audioVoice === 'y' ?  details.ysypdz : details.msypdz"
           style="width: 0px;height: 0px;position: absolute;top: 0;">您的浏览器不支持 audio 标签。
    </audio>
    <main>
      <div class="boxLeft">
        <div class="background" v-for="(item, index) in textlist" :key="index">
          <img :src="textlist[index].ygjztpdz"
               :style="(currentIndex !== null ? index == currentIndex : index == 0) ? 'visibility:visible;' : ''"
               alt="">
        </div>
      </div>
      <div class="right">
        <div class="title">
          <p> {{ details.kwmc.substring(0, 9) }} </p>
          <p> {{ details.kwmc.substring(9) }} </p>
        </div>
        <div class="bigbox">
          <div class="box" id="box">
            <p class="currentDom" v-for="(item, index) in textlist" :key="index"
               :class="{active: (currentIndex >= 0 && index == currentIndex), activeNext: (currentIndex >= 0 && index == currentIndex - 1) || (currentIndex >= 0 && index == currentIndex + 1)}"
               @click="clickSentence(index)"> {{ item.jznr }} </p>
          </div>
        </div>
        <div class="right-footer" id="footer">
          <div class="wait" v-if="wait">评分中...</div>
          <!-- 动画 -->
          <div class="luyin" id="luyin" v-if="isPress"></div>
          <div class="lyafter" v-if="result[currentIndex] && !isPress && !wait">
            <div class="afterbox">
              <p>恭喜你，本次获得</p>
              <el-rate
                  v-model="result[currentIndex].total_score"
                  disabled
                  show-score
                  disabled-void-color="#D0D0D0"
                  :colors="['#FEA20B', '#FEA20B', '#FEA20B']"
                  score-template="{value}">
              </el-rate>
            </div>
            <div class="score" id="score">
              <div>
                <span id="standard_score">{{ '标准度：' + result[currentIndex].standard_score + '%' }}</span>
                <span id="accuracy_score">{{ '准确度：' + result[currentIndex].accuracy_score + '%' }}</span>
              </div>
              <div>
                <span id="fluency_score">{{ '流利度：' + result[currentIndex].fluency_score + '%' }}</span>
                <span id="integrity_score">{{ '完整度：' + result[currentIndex].integrity_score + '%' }}</span>
              </div>
            </div>
          </div>
          <div class="bottom">
            <!-- 重 -->
            <div @click="again"></div>
            <!-- v-if="isPc" -->
            <div @click="touchclick">{{ isPress ? '结束跟读' : '点击跟读' }}</div>
            <!-- <div v-if="!isPc" @touchstart="touchstart" @touchend="touchend">{{ isPress ? '结束跟读' : '按住跟读' }}</div> -->
            <!-- 下一步，右箭头 -->
            <div class="nextsentence" v-if="currentIndex && currentIndex == textlist.length - 1" @click="next(1)"></div>
            <!-- 下一句 -->
            <div class="nextstep" v-else @click="next(2)"></div>
          </div>
        </div>
      </div>
    </main>
    <Header :header_data="header_data" :noClicking="true"/>
  </div>
</template>
<script>
import IseRecorder from '@/assets/js/index'
import Header from "../components/zjfheader.vue";
import Step from "../components/step.vue";
import {onMounted, reactive, toRefs} from "vue-demi";
import {getCurrentInstance, nextTick, ref, onBeforeUnmount} from 'vue'
import {ElMessage} from "element-plus"
import {useStore} from 'vuex'
import {getEnglishText, getTextContent, saveMyBreakpoint, getMyBreakpoint} from '@/api/student'

export default {
  components: {
    Header,
    Step,
  },
  setup() {
    const audio = ref(null)
    const store = useStore()
    const {appContext} = getCurrentInstance()
    const that = appContext.config.globalProperties
    const data = reactive({
      audioVoice: '',
      details: {kwmc: ''},
      header_data: [],
      textlist: [{}],
      currentIndex: null, // 当前句子索引
      timer: null,
      learningIndex: 0, // 正在学的句子的索引
      isPress: false, // 当前是否正在按住
      result: [], // 成绩
      isPc: false,
      IseRecorder: null,
      wait: false,
      notes: {},
      studyTimer: null,
      studyTime: 0
    })
    // 展示结果
    window.showResult = (res) => {
      data.result[data.currentIndex] = res
      data.wait = false
      data.notes.xxkwsc += data.studyTime * 1000
      data.studyTime = 0
      data.notes.details.studyFirstStep = data.result
      preserve()
    }
    onMounted(() => {
      let params = {
        ddzt: 1,
        dyid: that.$route.query.dyid,
        xsid: store.state.userInfo.yhid,
        zid: that.$route.query.zid,
        zsdid: sessionStorage.getItem('zsdid')
      }
      getMyBreakpoint(params).then(ele => {
        if (ele.success) {
          let notes = ele.result.ddxx
          data.notes = JSON.parse(notes)
          data.currentIndex = data.notes.details.studyFirstStep ? data.notes.details.studyFirstStep.length - 1 : 0
          data.result = data.notes.details.studyFirstStep ? data.notes.details.studyFirstStep : []
          data.notes.step = 'studyFirstStep'
          preserve()
          data.studyTimer = setInterval(() => {
            data.studyTime++
          }, 1000)
          // 获取课文内容
          getTextContent({kwid: that.$route.query.kwid}).then(res => {
            if (res.success) {
              if (res.result.length) {
                data.textlist = res.result
                audio.value.load()
                nextTick(() => {
                  active(data.currentIndex)
                  play(data.textlist[data.currentIndex][data.audioVoice + 'sypwzq'], data.textlist[data.currentIndex][data.audioVoice + 'sypwzz'])
                  setTimeout(() => {
                    audio.value.muted = false
                  }, 100)
                })
              }
            } else {
              ElMessage({
                message: res.resultDesc,
                type: 'warning'
              })
            }
          })
        } else {
          ElMessage({
            message: ele.resultDesc,
            type: 'warning'
          })
        }
      })
      data.IseRecorder = new IseRecorder()
      data.isPc = !(/(iPhone|iPad|iPod|iOS|Android)/i.test(window.navigator.userAgent))
      data.audioVoice = localStorage.getItem(`sound_${store.state.userInfo.realname}`) || 'y'
      // 获取课文发音
      getEnglishText({kwid: that.$route.query.kwid}).then(res => {
        if (res.success) {
          data.details = res.result
          data.header_data = [
            {title: "图谱英语 — ", path: "newEnglish"},
            {
              title: that.$route.query.zmc + " — ",
              path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}`
            },
            {
              title: that.$route.query.dymc + " — ",
              path: `/chapter?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}`
            },
            {title: res.result.kwmc, path: ""},
          ]
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
    })
    // 再次播放
    const again = () => {
      if (data.isPress) {
        return false
      }
      if (data.wait) {
        return false
      }
      if (data.timer) {
        clearTimeout(data.timer)
      }
      audio.value.pause()
      play(data.textlist[data.currentIndex][data.audioVoice + 'sypwzq'], data.textlist[data.currentIndex][data.audioVoice + 'sypwzz'])
    }
    // 保存进度
    const preserve = () => {
      let params = {
        ddxx: JSON.stringify(data.notes),
        ddzt: 1,
        dyid: that.$route.query.dyid,
        xsid: store.state.userInfo.yhid,
        zid: that.$route.query.zid,
        zsdid: sessionStorage.getItem('zsdid')
      }
      saveMyBreakpoint(params).then(res => {
        console.log('学习记录保存成功')
      })
    }
    // 播放
    const play = (start, end) => {
      if (data.timer) {
        clearTimeout(data.timer)
      }
      let s = Number(start.split(':')[0]) * 60 + Number(start.split(':')[1]) + Number(start.split(':')[2]) / 1000
      let e = Number(end.split(':')[0]) * 60 + Number(end.split(':')[1]) + Number(end.split(':')[2]) / 1000
      audio.value.currentTime = s
      audio.value.play()
      data.timer = setTimeout(() => {
        audio.value.pause()
        clearTimeout(data.timer)
      }, (e - s) * 1000)
    }
    // 销毁钩子
    onBeforeUnmount(() => {
      if (data.timer) {
        clearTimeout(data.timer)
      }
      clearInterval(data.studyTimer)
      audio.value.pause()
    })

    // 点击句子
    function clickSentence(val) {
      if (data.isPress) {
        return false
      }
      if (data.wait) {
        return false
      }
      if (data.currentIndex === val) {
        return false
      }
      if (data.result.length < val) {
        ElMessage({
          message: '还未学到那里呢~',
          type: 'warning'
        })
        return false
      }
      if (data.result.length === val && data.result[val - 1].total_score < 0) { //3
        ElMessage({
          message: '获得三颗星才能到下一句哦~',
          type: 'warning'
        })
        return false
      }
      active(val)
      play(data.textlist[val][data.audioVoice + 'sypwzq'], data.textlist[val][data.audioVoice + 'sypwzz'])
    }

    // 下一步
    function next(val) {
      if (data.isPress) {
        return false
      }
      if (data.wait) {
        return false
      }
      // 下一句
      if (val == 2) {
        if (data.result[data.currentIndex]) {
          if (data.result[data.currentIndex].total_score >= 0) { //3
            data.currentIndex++
            active(data.currentIndex)
            play(data.textlist[data.currentIndex][data.audioVoice + 'sypwzq'], data.textlist[data.currentIndex][data.audioVoice + 'sypwzz'])
          } else {
            ElMessage({
              message: '获得三颗星才能到下一句哦~',
              type: 'warning'
            })
          }
        } else {
          ElMessage({
            message: '完成跟读才能到下一句哦~',
            type: 'warning'
          })
        }
        // 下一步
      } else {
        if (data.result[data.currentIndex]) {
          clearInterval(data.studyTimer)
          data.notes.xxkwsc += data.studyTime * 1000
          let params = {
            ddxx: JSON.stringify(data.notes),
            ddzt: 1,
            dyid: that.$route.query.dyid,
            xsid: store.state.userInfo.yhid,
            zid: that.$route.query.zid,
            zsdid: sessionStorage.getItem('zsdid')
          }
          saveMyBreakpoint(params).then(res => {
            console.log('学习记录保存成功')
            that.$router.replace({
              name: 'studyTwoStep',
              query: {
                dyid: that.$route.query.dyid,
                dymc: that.$route.query.dymc,
                zmc: that.$route.query.zmc,
                zid: that.$route.query.zid,
                kwid: that.$route.query.kwid
              }
            })
          })
        } else {
          ElMessage({
            message: '完成跟读才能到下一步哦~',
            type: 'warning'
          })
        }
      }
    }

    // 高亮
    const active = (index) => {
      sessionStorage.setItem('audioText', data.textlist[index].jznr)
      data.currentIndex = index
      setTimeout(() => {
        var oScrollDom = document.getElementById('box')
        oScrollDom.scrollTop += document.getElementsByClassName('currentDom')[index].getBoundingClientRect().top - oScrollDom.getBoundingClientRect().top - oScrollDom.offsetHeight / 2 + document.getElementsByClassName('currentDom')[index].offsetHeight / 2
      }, 100)
    }
    // 按住
    const touchstart = () => {
      data.wait = false
      audio.value.pause()
      data.isPress = true
      data.IseRecorder.start()
    }
    // 松手
    const touchend = () => {
      if (data.wait) {
        return false
      }
      data.isPress = false
      data.wait = true
      data.IseRecorder.stop()
    }
    // 点击
    const touchclick = () => {
      if (data.isPress) {
        data.isPress = false
        data.wait = true
        data.IseRecorder.stop()
      } else {
        data.wait = false
        audio.value.pause()
        data.isPress = true
        data.IseRecorder.start()
      }
    }
    return {
      ...toRefs(data),
      clickSentence,
      next,
      active,
      play,
      audio,
      again,
      touchstart,
      touchend,
      touchclick,
      showResult,
      preserve
    }
  },
};
</script>
<style lang="scss" scoped>
@media (max-height: 700px) {
  #score {
    margin: 0;
  }
}

.studyFirstStep {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/zjfstudyBanner.png") no-repeat;
  background-size: 100% 100%;

  .wait {
    font-size: 22px;
    text-align: center;
    padding-top: 50px;
  }

  .leftnav {
    height: 65%;
    position: absolute;
    left: 4.5vw;
    top: 9vw;
    z-index: 3;
  }

  main {
    width: 94.78%;
    height: 83.925%;
    background: url("../../../assets/student/wordbanner.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: .2vw .2vw .2vw 0;

    .boxLeft {
      width: 71.2vw;
      height: 100%;
      float: left;
      box-sizing: border-box;
      padding: 1vw 0 1vw 2vw;
      position: relative;

      .background {
        position: absolute;
        top: -2vw;
        left: 3.5vw;
        right: 0;
        bottom: -2vw;

        img {
          display: block;
          height: 100%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          visibility: hidden;
        }
      }
    }

    .right {
      height: 100%;
      width: 23.3vw;
      border-radius: 3.125vw;
      box-sizing: border-box;
      padding-top: 1vw;
      position: relative;
      float: right;

      .title {
        width: 100%;
        text-align: center;
        font-size: 1.6vw;

        p {
          font-family: 'Times New Roman', Times, serif;
        }
      }

      .bigbox {
        position: absolute;
        top: 15%;
        bottom: 30%;
        right: -.23vw;
        overflow: hidden;

        .box {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 50% 1vw;
          overflow: auto;
          cursor: pointer;

          p {
            color: #666666;
            font-size: 1.3vw;
            line-height: 2.5vw;
            font-family: 'Times New Roman', Times, serif;
          }
        }
      }

      .right-footer {
        width: 100%;
        height: 30%;
        position: absolute;
        bottom: 0;

        .luyin {
          width: 7.708vw;
          height: 5vw;
          background: url('../../../assets/student/gifluyin.gif') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          top: 33%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .lyafter {
          .afterbox {
            padding-top: 1vw;
            display: flex;
            justify-content: center;

            p {
              font-size: 1.3vw;
              float: left;
            }

            :deep(.el-rate) {
              float: left;
            }

            :deep(.el-rate__icon) {
              font-size: 1.7vw;
              margin: 0;
            }

            :deep(.el-rate__text) {
              display: none;
            }
          }

          .score {
            margin-top: 3%;
            display: flex;
            flex-direction: column;

            div {
              text-align: center;

              span {
                margin-right: 2%;
              }

              span:nth-child(2) {
                margin: 0;
              }
            }
          }
        }

        .bottom {
          position: absolute;
          bottom: 5%;
          left: 50%;
          transform: translateX(-50%);
          width: 92%;
          display: flex;
          justify-content: space-around;

          div {
            cursor: pointer;
          }

          div:nth-child(1) {
            width: 3.645vw;
            height: 3.541vw;
            background: url('../../../assets/student/chong.png') no-repeat;
            background-size: 100% 100%;
          }

          div:nth-child(2) {
            width: 12.34vw;
            height: 3.75vw;
            background: url('../../../assets/student/startluyin.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            font-size: 1.3vw;
            box-sizing: border-box;
            padding-right: 2vw;
            text-align: right;
            line-height: 3.75vw;
          }

          .nextstep {
            width: 4.0625vw;
            height: 3.9583vw;
            background: url('../../../assets/student/nextsentence.png') no-repeat;
            background-size: 100% 100%;
          }

          .nextsentence {
            width: 4.0625vw;
            height: 3.9583vw;
            background: url('../../../assets/student/next.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }

  .active {
    color: #578EFF !important;
    font-size: 1.5vw !important;
  }

  .activeNext {
    color: #000000 !important;
    font-size: 1.4vw !important;
  }

  ::-webkit-scrollbar {
    width: .6vw;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
  }

  // 修改popover样式
  .el-popper.is-light {
    top: -10px !important;
  }
}
</style>